export const Welcome = () => {
  return (
    <div className="welcome">
      <h2>Hello, I'm Réka!</h2>
      <span>
        I’m a designer specializing in application and web UX/UI design, research, and marketing. With over three years in web design and creative marketing for a tech company, I excel at
        collaborating with developers.
      </span>
      <br />
      <br />
      <span>Driven by curiosity and an eye for detail, I strive for continuous growth in every project I take on.</span>
      <br />
      <br />
      <a href="https://drive.google.com/drive/mobile/folders/1rQYpeTOHPaPg-djW1SH5xcJuN7cjtcln?pli=1" target="_blank" className="portfolio-link">
        You can download my CV and Portfolio here
      </a>
    </div>
  );
};
