import React from "react";
export const Projects = () => {
  return (
    <>
      <div className="project project-1">
        <div className="content">
          <h3>TRAVL</h3>
          <div>Discover your ultimate travel companion with TRAVL. Plan your trips, book hotels, and explore destinations effortlessly with our comprehensive travel guide and program planner.</div>
          <a href="/projects/travl">Read more</a>
        </div>
        <a className="project-image" href="/projects/travl">
          <img src="travl-0.png" alt="TRAVL project" />
        </a>
      </div>

      <div className="project project-2">
        <a className="project-image" href="/projects/d8">
          <img src="d8-1.png" alt="d8" />
        </a>
        <div className="content">
          <h3>D8</h3>
          <div>
            D8 is about meeting people online without the heavy feeling that you’re shopping, it is about slowing down the pace of relationship development so that people are encouraged to focus on
            individuals and not always be looking at the next person.
          </div>
          <a href="/projects/d8">Read more</a>
        </div>
      </div>

      <div className="project project-3">
        <div className="content">
          <h3>Flight.co</h3>
          <div>
            Flight is a comprehensive UX/UI design project that emphasizes functionality, iterative design, and continuous learning. It showcases a thorough design process from initial sketches to
            final implementation, earning professional recognition and diploma.
          </div>
          <a href="/projects/flight">Read more</a>
        </div>
        <a className="project-image" href="/projects/flight">
          <img src="Flight.png" alt="flight" />
        </a>
      </div>
    </>
  );
};
